<template>
	<article-wrapper
			:progressColor="`#0096D6`">

			<template v-slot:hero>
				<hero class="csr__hero">
					<figure>Corporate social responsibility</figure>
					<h1>Csr in action</h1>
					<p>Our employees have supported their communities through virtual food drives, donations of telephones, personal protective equipment and hygenine kits, volunteering time to distribute food and creating photos and video to encourage patients in local hospitals. </p>
				</hero>
			</template>

			<template v-slot:body>
				<div class="csr__body">
					<h2 class="large" style="margin-top: 0">Supporting our Communities<br> <span>Through the Pandemic</span></h2>

					<div class="covid">
						<img src="~@/assets/csr/actionCovid1.svg" width="370" height="207" />

						<div></div>

						<img src="~@/assets/csr/actionCovid2.svg" width="550" height="206" />
					</div>

					<div class="covid--mobile">
						<img src="~@/assets/csr/actionCovidMobile.svg" width="545" />
					</div>
				</div>

				<div class="body-quote">
					<div class="body-quote__before"></div>
					<div class="body-quote__content body-quote__content--right">
						<div class="body-quote__quote">
							“During my time at DTCC, I’ve met and worked with so many wonderful colleagues who are dedicated and passionate about supporting our local communities. It’s exciting to work with people who share my drive and passion to give back by helping those in need. It’s also been a gateway to expand my professional network and meet new people inside and outside the company.”
							<div class="body-quote__credit">— <strong>Joseph King</strong></div>
						</div>
					</div>
					<div class="body-quote__after"></div>
				</div>

				<div class="csr__body">

					<h2 class="large" style="margin-top: 0">Supporting COVID efforts and <br> <span>communities around the world</span></h2>

					<div class="image-list">
						<div class="image-list__item image-list__item--reverse">
							<div>
								<h2>Feeding Tampa bay</h2>
								<p>Our DTCC Tampa office was honored with the first-ever Helping Hands Award by Feeding Tampa Bay in support of local COVID-19 relief efforts. Our volunteer and financial contributions aided the non-profit in serving 10,000 families a week hearty and healthy meals during the pandemic.</p>
							</div>
							<img src="~@/assets/csr/actionImg1@2x.jpg" width="548" />
						</div>

						<div class="image-list__item">
							<div>
								<h2>Sevalaya</h2>
								<p>DTCC employees in Chennai give back to their community by supporting Sevalaya, a charity which provides food, clothing and shelter for orphaned children and elders in India. As well as donating 2.34 Lakhs [$32,000] for supplies and college scholarships we also answered the call during the pandemic by supporting the installation of solar powered water heaters and washing machines for the Sevalaya homes.</p>
							</div>
							<img src="~@/assets/csr/actionImg2@2x.jpg" width="548" />
						</div>

						<div class="image-list__item image-list__item--reverse">
							<div>
								<h2>World vision Philippines</h2>
								<p>World Vision Philippines, is a global humanitarian organization focused on improving the lives of children in poverty stricken communities. DTCC Manila donated 114,000PHP (2,240 USD) to World Vision Philippines to aid 4,500 families, 100 health facilities and 100 health care centers. Our Manila employees also distributed hygiene kits to frontliners in the medical and security fields.</p>
							</div>
							<img src="~@/assets/csr/actionImg3@2x.jpg" width="548" />
						</div>

						<div class="image-list__item">
							<div>
								<h2>Nightingale Hospice </h2>
								<p>DTCC Wrexham has been a long times supporter of the Nightingale Hospice, which provides specialist palliative care services for those with life-limiting illnesses, and the pandemic did not slow down efforts to continue to raise funds towards the modernization project, relocating and restructuring the current daycare and rehabilitation facilities. £10,000 [$13,000] was raised during 2020 towards the facility upgrades.  </p>
							</div>
							<img src="~@/assets/csr/actionImg4@2x.jpg" width="548" />
						</div>

						<div class="image-list__item image-list__item--reverse">
							<div>
								<h2>Leukemia & Lymphoma Society</h2>
								<p>Of course, our fundraising did not only focus on those impacted by the pandemic – reasons for donating and volunteering can differ and often our personal experience comes into play as to where and how we choose to give back. Employees across four of our US locations collectively raised $28,945 through a fundraiser in aid of the Leukemia & Lymphoma Society(LLS) for the life-saving research and support the organization gives to cancer patients and their families. This amount exceeded the fundraising goal by $9,000.</p>
							</div>
							<img src="~@/assets/csr/actionImg5@2x.jpg" width="548" />
						</div>

						<div class="image-list__item">
							<div>
								<figure>Virlanie</figure>
								<h2>Taking CSR Activity <br><span>Online</span></h2>
								<p>We are a long-time supporter of Virlanie, a non-profit which reaches out to street children in Manilla. With in-person activities restricted due to COVID-19 we reimagined how we could engage with the kids and organized two fun virtual sessions that focused on COVID awareness, origami and the importance of environmental sustainability and recycling.</p>
							</div>
							<img src="~@/assets/csr/actionImg6@2x.jpg" width="548" />
						</div>

						<div class="image-list__item image-list__item--reverse">
							<div>
								<h2><span>Step Up</span> for <br> Students Program</h2>
								<p>DTCC has been participating in the Step Up For Students program through the Florida Tax Credit Scholarship since 2019. Through the Florida Tax Credit (FTC) Scholarship Program (Florida Statutes, Section 1002.395) dollar-for-dollar tax credits are provided to companies that redirect certain Florida tax liabilities to Step Up For Students, a state-approved Scholarship Funding Organization (SFO). The redirected tax dollars fund education scholarships for lower-income Florida children in Kindergarten through 12th grade. </p>
							</div>
							<img src="~@/assets/csr/actionImg7@2x.jpg" width="548" />
						</div>

					</div>
				</div>
			</template>

			<template v-slot:footer>

				<article-next class="csr__next" :image="'csr/bgSustainability@2x.jpg'" :link="{name: 'csr', params: { article: 'sustainability'}}" target-id="next_article" v-hero-click="`#next_article`">
					<template v-slot:label>Corporate Social Responsibility</template>
					<template v-slot:title>Sustainability at DTCC</template>
				</article-next>

			</template>

		</article-wrapper>
</template>

<script>
import ArticleNext from '@/components/ArticleNext'
import ArticleWrapper from '@/components/ArticleWrapper'
import Hero from '@/components/Hero'
import Boxes from '@/components/Boxes'

export default {
	name: 'Action',
	components: {
		ArticleNext,
		ArticleWrapper,
		Hero,
		Boxes
	},
}
</script>

<style scoped lang="scss">

.csr {

	&__hero {
		background-image: url('~@/assets/csr/bgAction@2x.jpg');
	}

	.body-quote {
		background-image: url('~@/assets/csr/bgActionQuote@2x.jpg');
	}

	.covid {
		@include gutter('margin-top', 3);
		@include gutter('margin-bottom', 1);
		display: none;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;

		@media only screen and (min-width: 1100px) {
			display: flex;
		}

		@include media-query-min-width('tablet') {
			flex-direction: row;
		}

		img {


			@include media-query('tablet') {
				max-height: 160px;
			}
		}

		div {
			@include gutter('margin-top', 2);
			@include gutter('margin-bottom', 2);
			background-color: color_('primary');
			//display: none;
			width: 2px;

			width: 100%;
			height: 2px;

			@include media-query-min-width('tablet') {
				//display: block;
				@include gutter('margin-top', 0);
				@include gutter('margin-bottom', 0);
				width: 2px;
				height: 210px;
			}
		}

		&--mobile {
			@include gutter('margin-top', 3);
			@include gutter('margin-bottom', 1);

			img {
				max-width: 100%;
			}

			@media only screen and (min-width: 1100px) {
				display: none;
			}
		}
	}
}

</style>
